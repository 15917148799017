import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	BusinessLogger,
	BusinessLoggerSymbol,
	SiteFeatureConfigSymbol,
	TpaHandlerProvider,
} from '@wix/thunderbolt-symbols'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'
import { runtimeTpaCompIdBuilder } from '@wix/thunderbolt-commons'

export type MessageData = { stageNum: number; stage: string }
export type HandlerResponse = void

export const ApplicationLoadingStepHandler = withDependencies(
	[BusinessLoggerSymbol, named(SiteFeatureConfigSymbol, tpaCommonsName)],
	(businessLogger: BusinessLogger, { debug }: TpaCommonsSiteConfig): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				applicationLoadingStep(compId, msgData: MessageData, { appDefinitionId, widgetId }): HandlerResponse {
					if (runtimeTpaCompIdBuilder.isRuntimeCompId(compId)) {
						if (debug) {
							console.warn(`applicationLoadingStep is ignored in runtime component ${compId}`)
						}
						return
					}

					const { stage, stageNum } = msgData
					businessLogger.logger.log(
						{
							appId: appDefinitionId,
							widget_id: widgetId,
							instance_id: compId,
							src: 42,
							evid: 644,
							stage,
							stageNum,
						},
						{ endpoint: 'ugc-viewer' }
					)
				},
			}
		},
	})
)
