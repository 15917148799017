import { named, withDependencies } from '@wix/thunderbolt-ioc'
import { extractInnerRoute } from '@wix/thunderbolt-commons'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	CurrentRouteInfoSymbol,
	ISamePageUrlChangeListener,
	PageFeatureConfigSymbol,
	pageIdSym,
} from '@wix/thunderbolt-symbols'
import { ICurrentRouteInfo } from 'feature-router'
import { ITPAEventsListenerManager, TpaPageConfig } from './types'
import { TpaEventsListenerManagerSymbol, name } from './symbols'

export const TpaStateManager = withDependencies(
	[
		TpaEventsListenerManagerSymbol,
		CurrentRouteInfoSymbol,
		BrowserWindowSymbol,
		named(PageFeatureConfigSymbol, name),
		pageIdSym,
	],
	(
		tpaEventsListenerManager: ITPAEventsListenerManager,
		currentRouteInfo: ICurrentRouteInfo,
		browserWindow: BrowserWindow,
		{ tpaInnerRouteConfig }: TpaPageConfig,
		pageId: string
	): ISamePageUrlChangeListener => {
		return {
			onUrlChange() {
				const routerInfo = currentRouteInfo.getCurrentRouteInfo()
				if (!routerInfo) {
					return
				}

				const parts = extractInnerRoute(
					routerInfo!.relativeUrl.replace(/^\./, ''),
					tpaInnerRouteConfig.tpaPageUri
				)
				const newState = parts ? parts.join('/') : ''

				tpaEventsListenerManager.dispatch(
					'STATE_CHANGED',
					() => {
						return { newState }
					},
					{ pageId }
				)
			},
		}
	}
)
