import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	BusinessLogger,
	BusinessLoggerSymbol,
	SiteFeatureConfigSymbol,
	TpaHandlerProvider,
} from '@wix/thunderbolt-symbols'
import { name as tpaCommonsName, TpaCommonsSiteConfig } from 'feature-tpa-commons'
import { runtimeTpaCompIdBuilder } from '@wix/thunderbolt-commons'

export type MessageData = {}
export type HandlerResponse = void

export const ApplicationLoadedHandler = withDependencies(
	[BusinessLoggerSymbol, named(SiteFeatureConfigSymbol, tpaCommonsName)],
	(businessLogger: BusinessLogger, { debug }: TpaCommonsSiteConfig): TpaHandlerProvider => ({
		getTpaHandlers() {
			return {
				applicationLoaded(compId, _msgData, { appDefinitionId, widgetId }): HandlerResponse {
					if (runtimeTpaCompIdBuilder.isRuntimeCompId(compId)) {
						if (debug) {
							console.warn(`applicationLoaded is ignored in runtime component ${compId}`)
						}
						return
					}

					businessLogger.logger.log(
						{
							appId: appDefinitionId,
							widget_id: widgetId,
							instance_id: compId,
							src: 42,
							evid: 643,
						},
						{ endpoint: 'ugc-viewer' }
					)
				},
			}
		},
	})
)
